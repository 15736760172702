:root {
  --body-1-bold-font-family: "Inter-SemiBold", Helvetica;
  --body-1-bold-font-size: 16px;
  --body-1-bold-font-style: normal;
  --body-1-bold-font-weight: 600;
  --body-1-bold-letter-spacing: 0px;
  --body-1-bold-line-height: 150%;
  --body-1-font-family: "Inter-Regular", Helvetica;
  --body-1-font-size: 16px;
  --body-1-font-style: normal;
  --body-1-font-weight: 400;
  --body-1-letter-spacing: 0px;
  --body-1-line-height: 150%;
  --body-2-bold-font-family: "Inter-SemiBold", Helvetica;
  --body-2-bold-font-size: 14px;
  --body-2-bold-font-style: normal;
  --body-2-bold-font-weight: 600;
  --body-2-bold-letter-spacing: 0px;
  --body-2-bold-line-height: 150%;
  --button-1-font-family: "Inter-SemiBold", Helvetica;
  --button-1-font-size: 16px;
  --button-1-font-style: normal;
  --button-1-font-weight: 600;
  --button-1-letter-spacing: 0px;
  --button-1-line-height: 150%;
  --button-2-font-family: "Inter-SemiBold", Helvetica;
  --button-2-font-size: 14px;
  --button-2-font-style: normal;
  --button-2-font-weight: 600;
  --button-2-letter-spacing: 0px;
  --button-2-line-height: 150%;
  --CP-light-font-family: "Inter-Regular", Helvetica;
  --CP-light-font-size: 12px;
  --CP-light-font-style: normal;
  --CP-light-font-weight: 400;
  --CP-light-letter-spacing: 0px;
  --CP-light-line-height: 150%;
  --heading-1-mobile-font-family: "Inter-Medium", Helvetica;
  --heading-1-mobile-font-size: 18px;
  --heading-1-mobile-font-style: normal;
  --heading-1-mobile-font-weight: 500;
  --heading-1-mobile-letter-spacing: 0px;
  --heading-1-mobile-line-height: 150%;
  --neutralblack: #2c2c2c;
  --neutraldark-grey: #aeaeae;
  --neutralgrey: #f9f9f9;
  --neutralwhite: #fff;
  --primary-1: #4282ff;
}
/*# sourceMappingURL=index.85df569a.css.map */
